import { CONVERT_TOKO_STATUS } from '@/utils/key-mapping'

export default {
  methods: {
    formatConvertTokoOrderStatus(status) {
      let statusEl

      switch (status) {
        case CONVERT_TOKO_STATUS.sendInitial:
          statusEl = '<span class="text-xs">Send</span><br><p class="text-uppercase">Initial</p>'
          break
        case CONVERT_TOKO_STATUS.sendPending:
          statusEl = '<span class="text-xs">Send</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case CONVERT_TOKO_STATUS.sendFailed:
          statusEl = '<span class="text-xs">Send</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case CONVERT_TOKO_STATUS.sendSuccess:
          statusEl =
            '<span class="text-xs">Send</span><br><p class="text-bold text-uppercase text-success text-uppercase">Success</p>'
          break
        case CONVERT_TOKO_STATUS.sendCancel:
          statusEl = '<span class="text-xs">Send</span><br><p class="text-uppercase">Cancel</p>'
          break
        case CONVERT_TOKO_STATUS.receivePending:
          statusEl =
            '<span class="text-xs text-warning">Receive</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case CONVERT_TOKO_STATUS.receiveFailed:
          statusEl =
            '<span class="text-xs text-warning">Receive</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case CONVERT_TOKO_STATUS.receiveSuccess:
          statusEl =
            '<span class="text-xs text-warning">Receive</span><br><p class="text-bold text-uppercase text-success text-uppercase">Success</p>'
          break
      }

      return statusEl
    }
  }
}
